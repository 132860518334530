<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Chat Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="searchVal"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-text-field
        v-model="to"
        label="To"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="all"
      :page.sync="page"
      @page-count="pageCount = $event"
      :options.sync="options"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :server-items-length="$store.state.gameLogs.chatAll.totalPage"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [50, 100, 500, 1000, 2000],
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      :item-key="
        new Date().getTime() + parseInt(Math.random() * 100).toString()
      "
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>

      <template v-slot:item.c="{ item }">
        {{ item.c | formatMoney }}
      </template>
      <template v-slot:item.t="{ item }">
        {{ new Date(item.t * 1000) | formatDateSS }}
      </template>
      <template v-slot:item.i="{ item }">
        <user-detail-card
          :userId="item.i"
          :pictureUrl="item.p"
          :userName="item.u"
          :level="item.l"
        />
      </template>
      <template v-slot:item.m="{ item }">
        <a
          v-if="item.m.indexOf('audio/') > -1"
          target="_blank"
          :href="'https://pokerface.restplay.com' + item.m.split(':')[1]"
          >{{ item.m }}</a
        >
        <span v-else>{{ item.m }}</span>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :total-visible="20"
        :length="$store.state.gameLogs.chatAll.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import UserDetailCard from "../../../components/Users/UserDetailCard.vue";
export default {
  components: { UserDetailCard },
  name: "GameLogListCard",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      all: [],
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      searchVal: {},
      to: "",

      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "User", value: "i" },
        { text: "Time", value: "t" },
        { text: "Message", value: "m" },
        { text: "Sit", value: "s" },
        { text: "ChipAmount", value: "c" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch("productPromos/delete", item.id);
      await this.initialize();
    },

    editItem() {},
    async initialize() {
      // eslint-disable-next-line no-unused-vars
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      await this.$store.dispatch("gameLogs/getChatAll", {
        search: this.searchVal,
        page: page - 1,
        to: this.to,
        total: itemsPerPage,
        options: this.options,
      });
      this.all = this.$store.state.gameLogs.chatAll.data;
    },
  },

  async mounted() {
    this.searchVal = this.search;
  },
};
</script>
