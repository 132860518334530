var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Chat Logs")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.initialize.apply(null, arguments)}},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"label":"To","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.initialize.apply(null, arguments)}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}}),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.initialize}},[_vm._v("Search")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.all,"page":_vm.page,"options":_vm.options,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.$store.state.gameLogs.chatAll.totalPage,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [50, 100, 500, 1000, 2000],
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    },"item-key":new Date().getTime() + parseInt(Math.random() * 100).toString()},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}},{key:"item.c",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.c))+" ")]}},{key:"item.t",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDateSS")(new Date(item.t * 1000)))+" ")]}},{key:"item.i",fn:function({ item }){return [_c('user-detail-card',{attrs:{"userId":item.i,"pictureUrl":item.p,"userName":item.u,"level":item.l}})]}},{key:"item.m",fn:function({ item }){return [(item.m.indexOf('audio/') > -1)?_c('a',{attrs:{"target":"_blank","href":'https://pokerface.restplay.com' + item.m.split(':')[1]}},[_vm._v(_vm._s(item.m))]):_c('span',[_vm._v(_vm._s(item.m))])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"total-visible":20,"length":_vm.$store.state.gameLogs.chatAll.totalPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }